import ACTIONS from "./actions";

export default {
    setAuthorizationToken: (token) => ({
        type: ACTIONS.SET_AUTHORIZATION_TOKEN,
        payload: token,
    }),
    closeSession: () => ({
        type: ACTIONS.CLOSE_SESSION,
    }),
    setNotification: (type, message) => ({
        type: ACTIONS.SET_NOTIFICATION,
        payload: { type, message },
    }),
    closeNotification: () => ({
        type: ACTIONS.CLOSE_NOTIFICATION,
    }),
    setUser: (payload) => ({
        type: ACTIONS.SET_USER,
        payload,
    }),
    setPreference: (payload) => ({
        type: ACTIONS.SET_PREFERENCE,
        payload,
    }),
    removePreference: (payload) => ({
        type: ACTIONS.REMOVE_PREFERENCE,
        payload,
    }),
    setHttpError: (payload) => ({
        type: ACTIONS.SET_HTTP_ERROR,
        payload,
    }),
    setHttpErrorSystem: (payload) => ({
        type: ACTIONS.SET_HTTP_ERROR_SYSTEM,
        payload,
    }),
    toggleSidebar: (payload) => ({
        type: ACTIONS.TOGGLE_RIGHT_SIDEBAR,
        payload,
    }),
    setOperator: (payload) => ({
        type: ACTIONS.SET_OPERATOR,
        payload,
    }),
    setCompany: (payload) => ({
        type: ACTIONS.SET_COMPANY,
        payload,
    }),
    /* setTabs: (payload) => ({
        type: ACTIONS.SET_TABS,
        payload,
    }),
    removeTabs: (payload) => ({
        type: ACTIONS.REMOVE_TABS,
        payload,
    }), */
};
