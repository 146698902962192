import React, { useState, useEffect, useRef } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Modal, Button, Carousel, Row, Spin } from 'antd';
import HttpRequest from '../../services/HttpRequest';

function PhotoList(props) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentPhotos, setCurrentPhotos] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const photoRef = useRef()

    const uploadPhoto = ({ file, onSuccess }) => {
        HttpRequest.endpoint = props.endpoint || "formerCustomers/remove_equipament_photo/";
        const formData = new FormData();
        formData.append(props.photoFieldName || 'remove_equipament', props.removeID);
        formData.append('image', file)
        HttpRequest.post(formData).then(res => {
            if (res && res.ID) {
                onSuccess("ok")
                Modal.success({
                    title: 'Éxito',
                    content: 'Se ha guardado la imagen con éxito'
                })
                getPhotos()
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error guardando la foto'
                })
            }
        })
    }

    const deletePhoto = () => {
        setButtonLoading(true);
        let photoToDelete;
        currentPhotos.forEach((photo, i) => {
            if (i === currentSlide) {
                photoToDelete = photo.ID;
            }
        })
        HttpRequest.endpoint = props.endpoint || "formerCustomers/remove_equipament_photo/";
        HttpRequest.delete(photoToDelete).then(res => {
            setButtonLoading(false);
            getPhotos()
        })
    }
    const getPhotos = () => {
        setLoading(true)
        HttpRequest.endpoint = props.endpoint || "formerCustomers/remove_equipament_photo/";
        const filters = {}
        if (props.photoFieldName) {
            filters[props.photoFieldName] = props.removeID
        } else {
            filters["remove_equipament"] = props.removeID
        }
        HttpRequest.get(filters).then(res => {
            if (res && Array.isArray(res)) {
                setCurrentPhotos(res)
                setLoading(false)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error obteniendo las fotos actuales'
                })
            }
        })
    }

    useEffect(() => {
        getPhotos()
    }, [])

    return (
        <>
            {
                loading ?
                    <Spin size='large' className='center-spin' />
                    :
                    <>
                        <Row justify='center'>
                            <Upload
                                customRequest={uploadPhoto}
                            >
                                <Button className='center-btn' icon={<UploadOutlined />}>Agregar foto</Button>
                            </Upload>
                        </Row>
                        <Carousel afterChange={setCurrentSlide} ref={photoRef} dots={false}>
                            {currentPhotos.map(photo => <img alt='Error cargando' key={photo.ID} src={photo.image} />)}
                        </Carousel>
                        {
                            currentPhotos.length > 0 &&
                            <Row justify='center'>
                                <Button type='primary' onClick={() => photoRef.current.prev()}>Anterior</Button>
                                <Button danger type='primary' onClick={deletePhoto} loading={buttonLoading}>Eliminar foto actual</Button>
                                <Button type='primary' onClick={() => photoRef.current.next()}>Siguiente</Button>
                            </Row>
                        }
                    </>
            }
        </>
    )
}

export default PhotoList;