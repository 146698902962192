import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Select, Modal, Input, Upload, Row } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import { connect } from 'react-redux';
import TestResultField from './TestResultField';
import { UploadOutlined } from '@ant-design/icons';

function Escalation(props) {
    const form = useRef();
    const [testGroups, setTestGroups] = useState([]);
    const [renderTests, setRenderTests] = useState([]);
    const [testsToSubmit, setTestsToSubmit] = useState({});
    const [selectedGroup, setSelectedGroup] = useState(null);

    const getTests = () => {
        HttpRequest.endpoint = "escalation_ti/test_group/";
        HttpRequest.get({ operator: props.operator }).then(res => {
            if (res && Array.isArray(res)) {
                setTestGroups(res)
            }
        })
    }
    const addTest = test => {
        setTestsToSubmit(prevState => {
            const testObj = { ...prevState }
            testObj[test.index] = { test: test.id, result: test.result }
            return testObj;
        })
    }

    const submitEscalation = e => {
        const testArray = Object.values(testsToSubmit)
        if (testArray.length > 0) {
            props.submit({ tests: testArray, comment: e.comment || null }, e.photos && e.photos.fileList && e.photos.fileList.length > 0 ? e.photos.fileList : null)
        } else {
            Modal.error({
                title: 'Error',
                content: 'Debe agregar al menos 1 prueba'
            })
        }
    }

    useEffect(getTests, [])

    useEffect(() => {
        if (selectedGroup) {
            console.log(testGroups)
            setTestsToSubmit({})
            testGroups.forEach(group => {
                if (group.ID === selectedGroup) {
                    const testArr = group.tests.map((test, i) => (
                        <Row className='escalation-typify-test-container'>
                            <p className='escalation-typify-test-name'><strong>Prueba:</strong> {test.name}</p>
                            <Select className='escalation-typify-test-result' placeholder='Resultado' onChange={value => addTest({ index: i, id: test.id, result: value })}>
                                {test.options.map(res => <Select.Option key={res.id} value={res.id}>{res.result}</Select.Option>)}
                            </Select>
                        </Row>
                    ));
                    setRenderTests(testArr);
                }
            })
        }
    }, [selectedGroup])

    return (
        <>
            <Form ref={form} name='escalation' onFinish={submitEscalation}>
                <Form.Item name='group'>
                    <Select placeholder='Seleccionar grupo de pruebas' onChange={val => setSelectedGroup(val)}>
                        {testGroups.map(group => <Select.Option key={group.ID} value={group.ID}>{group.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                {renderTests}
                <Form.Item name='comment'>
                    <Input.TextArea placeholder='Comentarios' />
                </Form.Item>
                <Form.Item name='photos'>
                    <Upload beforeUpload={() => false} multiple listType="picture">
                        <Button className='center-btn' icon={<UploadOutlined />}>Agregar fotos</Button>
                    </Upload>
                </Form.Item>
                <Button htmlType='submit' type='primary' block size='large' loading={props.loading}>Escalar</Button>
            </Form>

        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(Escalation);