import React, { useState } from 'react';
import FullTable from '../../components/datatable/FullTable';
import { Button, Modal } from 'antd';

function WhatsappTable(props) {
    const [tableRows, setTableRows] = useState([]);
    const [template, setTemplate] = useState('');

    let endpoints = {
        columns: "communications/whatsapp/" + props.tableID + "/datatables_struct/",
        rows: "communications/whatsapp/" + props.tableID + "/datatables/",
        delete: `communications/whatsapp/`,
        download: 'communications/whatsapp/' + props.tableID + '/download_data/',
    }
    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            message: <Button onClick={() => setTemplate(row.message)}>Ver mensaje</Button>
        })))
    }
    return (
        <>
            <FullTable handleRows={handleRows} tableRows={tableRows} modalWidth={800} buttons={[]} endpoints={endpoints} tableName='whatsapp' />
            <Modal footer={null} visible={template} onCancel={() => setTemplate('')}>
                <iframe width='750' height='350' srcDoc={template}></iframe>
            </Modal>
        </>
    )
}

export default WhatsappTable;