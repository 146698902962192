import {
	Calendar,
	Spin,
	ConfigProvider,
	Button,
	Modal,
	Select,
	Row,
} from "antd";
import React, { useState, useEffect } from "react";
import "./iclass.css";
import "moment/locale/zh-cn";
import moment from "moment";
import esES from "antd/lib/locale/es_ES";
import { Panel, PanelBody } from "../../components/panel/panel";
import HttpRequest from "../../services/HttpRequest";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function TimePicker(props) {
	const [loading, setLoading] = useState(false);
	const [availableElements, setAvailableElements] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [date, setDate] = useState(null);
	const [selectedHours, setSelectedHours] = useState([]);
	const [availableHours, setAvailableHours] = useState([]);
	const [orderKind, setOrderKind] = useState(0);
	const [defaultKind, setDefaultKind] = useState(0);
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [technician, setTechnician] = useState("");
	const [technicianOptions, setTechnicianOptions] = useState([]);
	const { Option } = Select;

	useEffect(() => {
		getAvailableTimes();
	}, [technician]);

	const kindList = [
		"Instalacion Hogar - UTP 1h",
		"Instalacion Hogar - UTP 2h",
		"Instalacion Hogar - Fibra 1h",
		"Instalacion Hogar - Fibra 2h",
		"Instalacion Hogar - Antena 2h",
		"Instalacion Hogar - Apoyo Instalacion 1h",
		"Instalacion Hogar - Apoyo Instalacion 2h",
		"Instalacion Hogar - Migracion a fibra 1h",
		"Instalacion Hogar - Migracion a fibra 2h",
	];
	moment.updateLocale("es", {
		weekdaysMin: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
	});
	const times = [
		"8:00",
		"",
		"8:30",
		"",
		"9:00",
		"",
		"9:30",
		"",
		"10:00",
		"",
		"10:30",
		"",
		"11:00",
		"",
		"11:30",
		"",
		"12:00",
		"",
		"12:30",
		"",
		"13:00",
		"",
		"13:30",
		"",
		"14:00",
		"",
		"14:30",
		"",
		"15:00",
		"",
		"15:30",
		"",
		"16:00",
		"",
		"16:30",
		"",
		"17:00",
		"",
		"17:30",
		"",
		"18:00",
		"",
		"18:30",
		"",
		"19:00",
		"",
		"19:30",
		"",
		"20:00",
		"",
		"20:30",
	];
	const timeElements = times.map((time, i) => {
		const styleObj = {
			gridRowStart: `${i + 1}`,
			fontSize: `${time.includes(":00") ? "0.9rem" : "0.6rem"}`,
			fontWeight: `${time.includes(":00") ? "700" : "300"}`,
		};
		return (
			<div key={i} style={styleObj}>
				<p
					className={
						time.includes(":00")
							? "iclass-time-option-even"
							: "iclass-time-option-odd"
					}
				>
					{time}
				</p>
			</div>
		);
	});
	const getAvailableTimes = () => {
		if (selectedDate) {
			setLoading(true);
			const availableTimes = [];
			HttpRequest.endpoint = "iclass/service_orders/availability/";
			const formData = new FormData();
			formData.append("service", 11655);
			formData.append("kind", "Retiro de equipo");
			// if (props.remove) {
			//     formData.append("kind", "Retiro de equipo");
			// }
			// else {
			//     if (orderKind == null) {
			//         Modal.error({
			//             title: "Error",
			//             content: "No se ha seleccionado el tipo de orden"
			//         })
			//         return;
			//     }
			//     formData.append("kind", kindOptions[orderKind]);
			// }
			formData.append("start", date.format("YYYY-MM-DD HH:mm"));
			HttpRequest.post(formData).then((res) => {
				if (res && Array.isArray(res)) {
					setTechnicianOptions(
						res.map((tecdata) => tecdata.technician)
					);
					res.forEach((tecdata) => {
						if (technician === tecdata.technician) {
							tecdata.hours.forEach((time, i) => {
								availableHours.push(time);
								let start = moment(time[0]);
								let start_str = start.format("hh:mm");
								let end = moment(time[1]).format("hh:mm");
								let span = Math.abs(
									start.diff(moment(time[1]), "minutes") / 15
								);
								let row = (start.hour() - 8) * 4 + 1;
								if (start_str.split(":")[1] != "00") {
									var dif =
										parseInt(start_str.split(":")[1]) / 15;
									row = row + dif;
								}
								/*const colors = {
                                    0: 'blue',
                                    1: 'red',
                                    2: 'yellow',
                                    3: 'green',
                                    4: 'black'
                                }*/
								const styleObj = {
									gridRow: `${row} / span ${span}`,
								};
								availableTimes.push(
									<div
										key={end}
										className="iclass-available-block"
										style={styleObj}
										onClick={(e) => {
											if (manageHours(i)) {
												if (
													e.target.className ==
													"iclass-available-block"
												) {
													e.target.className =
														"iclass-time-b";
												} else {
													if (
														e.target.className !=
														"iclass-time-b"
													) {
														e.target.parentElement
															.className ==
														"iclass-time-b"
															? (e.target.parentElement.className =
																	"iclass-available-block")
															: (e.target.parentElement.className =
																	"iclass-time-b");
													} else {
														e.target.className =
															"iclass-available-block";
													}
												}
											}
										}}
									>
										<p className="iclass-time">
											{start_str} - {end}
										</p>
										<p>Click para elegir disponibilidad</p>
									</div>
								);
							});
						} else {
						}
					});
					setAvailableElements(availableTimes);
					setLoading(false);
				} else {
					setAvailableElements([]);
					setLoading(false);
				}
			});
		}
	};
	const manageHours = (i) => {
		let valid = true;
		if (selectedHours.includes(i)) {
			const index = selectedHours.indexOf(i);
			if (index > -1) {
				selectedHours.splice(index, 1);
			}
		} else {
			if (
				selectedHours.includes(i + 1) ||
				selectedHours.includes(i - 1) ||
				selectedHours.length == 0
			) {
				selectedHours.push(i);
			} else {
				Modal.error({
					title: "Ha ocurrido un error",
					content: "Por favor seleccione horarios consecutivos",
				});
				return false;
			}
		}
		return true;
	};
	const createOrder = (excess = false) => {
		setLoadingBtn(true);
		let start = null;
		let end = null;
		let valid = true;
		let finalHours = [];
		if (selectedHours.length == 0) {
			Modal.error({
				title: "Ha ocurrido un error",
				content: "Por favor seleccione al menos un horario",
			});
			setLoadingBtn(false);
			valid = false;
			return;
		}
		selectedHours.forEach(function (element, i) {
			finalHours.push(availableHours[element]);
			finalHours[i][0] = moment(finalHours[i][0]);
			finalHours[i][1] = moment(finalHours[i][1]);
		});
		finalHours.sort();
		finalHours.forEach((hours) => {
			if (start == null) {
				start = hours[0];
				end = hours[1];
				return;
			}
			if (end.format("HH:mm") != hours[0].format("HH:mm")) {
				Modal.error({
					title: "Ha ocurrido un error",
					content: "Por favor seleccione horarios consecutivos",
				});
				setLoadingBtn(false);
				valid = false;
				return;
			}
			if (start == null || hours[0] < start) start = hours[0];
			if (
				end == null ||
				(end < hours[1] &&
					end.format("HH:mm") == hours[0].format("HH:mm"))
			)
				end = hours[1];
		});
		var timeSlice = checkTimeSlice(start, end);
		if (!timeSlice[0] && excess != true) {
			if (timeSlice[1] != "") {
				Modal.error({
					title: "Ha ocurrido un error",
					content:
						"Por favor seleccione un bloque de horario acorde al tipo de orden. Mínimo " +
						timeSlice[1],
				});
			} else {
				Modal.confirm({
					title: "Se ha seleccionado un tiempo mayor al necesario para este tipo de órden",
					icon: <ExclamationCircleOutlined />,
					content: "¿Desea proceder con la creación de la orden?",
					onOk() {
						createOrder(true);
					},
					onCancel() {},
				});
			}
			setLoadingBtn(false);
		}
		valid = excess == true ? true : timeSlice[0];
		if (valid) {
			const formData = new FormData();
			if (props.remove) {
				formData.append("kind", "Retiro de equipo");
			} else {
				formData.append("kind", kindList[orderKind]);
			}
			formData.append("subject", "");
			formData.append("start", start.format("YYYY-MM-DD HH:mm"));
			formData.append("end", end.format("YYYY-MM-DD HH:mm"));
			formData.append("service", props.service);
			formData.append("technician", technician);
			formData.append("creator_system", "Iris");
			formData.append(
				"creator",
				JSON.parse(localStorage.getItem("userInfo")).username
			);
			HttpRequest.endpoint = "iclass/service_orders/";
			HttpRequest.post(formData).then((res) => {
				if (res["id"]) {
					if (props.updateIclass) props.updateIclass(res["id"]);
					props.updateFunction(
						res["id"],
						start.format("YYYY-MM-DD HH:mm"),
						end.format("YYYY-MM-DD HH:mm")
					);
				} else {
					if (res["details"]["iclass"]) {
						Modal.error({
							title: "Error",
							content:
								"Ha ocurrido un error al conectarse con iclass. Error " +
								res["details"]["iclass"],
						});
					} else {
						Modal.error({
							title: "Error",
							content: "Ha ocurrido un error al crear la orden",
						});
					}
					setLoadingBtn(false);
				}
			});
		}
	};
	const getDay = (e) => {
		const days = [
			"Domingo",
			"Lunes",
			"Martes",
			"Miércoles",
			"Jueves",
			"Viernes",
			"Sábado",
		];
		const months = [
			"enero",
			"febrero",
			"marzo",
			"abril",
			"mayo",
			"junio",
			"julio",
			"agosto",
			"septiembre",
			"octubre",
			"noviembre",
			"diciembre",
		];
		const date = e._d;
		const monthDate = date.getDate();
		const day = days[date.getDay()];
		const month = months[date.getMonth()];
		const year = date.getFullYear();
		setDate(e.set({ hour: 0, minute: 0 }));
		setSelectedDate(`${day} ${monthDate} de ${month} del ${year}`);
		setLoading(true);
	};
	const getDefaultKind = (plan) => {
		if (plan.includes("FIBRA")) {
			setDefaultKind(2);
			setOrderKind(2);
		}
	};
	const checkTimeSlice = (start, end) => {
		var duration = moment.duration(end.diff(start));
		var hours = duration.asHours();
		if ((props.remove || kindList[orderKind].includes("1h")) && hours < 1) {
			return [false, "1h"];
		}
		if (kindList[orderKind].includes("2h") && hours < 2) {
			return [false, "2h"];
		}
		if (
			(kindList[orderKind].includes("2h") && hours > 2) ||
			(kindList[orderKind].includes("1h") && hours > 1)
		) {
			return [false, ""];
		}
		return [true, ""];
	};
	useEffect(() => {
		getAvailableTimes();
	}, [selectedDate]);
	useEffect(() => {
		if (props.plan) {
			getDefaultKind(props.plan);
		}
	}, []);
	useEffect(() => {
		getDay(moment());
	}, []);

	return (
		<Panel>
			<PanelBody>
				<ConfigProvider locale={esES}>
					{props.remove == null && (
						<div>
							<h5>Tipo de Orden</h5>
							<Select
								labelInValue
								defaultValue={{ value: defaultKind }}
								style={{ width: "500px", marginBottom: "10px" }}
								onChange={(value) => {
									setOrderKind(value.value);
								}}
							>
								<Option key={0} value={0}>
									Instalacion Hogar - UTP 1h
								</Option>
								<Option key={1} value={1}>
									Instalacion Hogar - UTP 2h
								</Option>
								<Option key={2} value={2}>
									Instalacion Hogar - Fibra 1h
								</Option>
								<Option key={3} value={3}>
									Instalacion Hogar - Fibra 2h
								</Option>
								<Option key={4} value={4}>
									Instalacion Hogar - Antena 2h
								</Option>
								<Option key={5} value={5}>
									Instalacion Hogar - Apoyo Instalacion 1h
								</Option>
								<Option key={6} value={6}>
									Instalacion Hogar - Apoyo Instalacion 2h
								</Option>
								<Option key={7} value={7}>
									Instalacion Hogar - Migracion a fibra 1h
								</Option>
								<Option key={8} value={8}>
									Instalacion Hogar - Migracion a fibra 2h
								</Option>
							</Select>
						</div>
					)}
					{availableElements ? (
						<>
							<div className="iclass-grid-header">
								<Button
									type="secondary"
									onClick={() => {
										setAvailableHours([]);
										setSelectedHours([]);
										setSelectedDate(null);
										setAvailableElements(null);
									}}
								>
									Atrás
								</Button>
								<p className="iclass-selected-date">
									{selectedDate}
								</p>
								<Select
									placeholder="Técnico"
									onChange={(val) => setTechnician(val)}
								>
									{technicianOptions.map((opt) => (
										<Select.Option key={opt} value={opt}>
											{opt}
										</Select.Option>
									))}
								</Select>
							</div>
							<div className="grid-container">
								{timeElements}
								{availableElements}
							</div>
							{props.remove ? (
								<Row justify="space-between">
									<h4>Crear la orden</h4>{" "}
									<Button
										loading={loadingBtn}
										type="primary"
										onClick={createOrder}
									>
										Listo
									</Button>
								</Row>
							) : (
								<Button
									size="large"
									type="primary"
									loading={loadingBtn}
									onClick={createOrder}
									className="wizard-btn center-btn"
								>
									Terminar
								</Button>
							)}
							{/* <Button onClick={createOrder}>
                                Continuar
                            </Button> */}
						</>
					) : (
						<>
							<Calendar onSelect={getDay} />
							{loading && (
								<Spin className="iclass-spinner" size="large" />
							)}
						</>
					)}
				</ConfigProvider>
			</PanelBody>
		</Panel>
	);
}

export default TimePicker;
