import React, { useState, useEffect } from 'react';
import FullTable from '../../components/datatable/FullTable';
import RemoveEquipForm from './removeEquipForm';
import Moment from 'react-moment';
import HttpRequest from "../../services/HttpRequest.js";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Input } from 'antd';
import { can } from '../../helpers/helpers';
import { removeEquipment } from '../../config/permissions';
import RegisterIclass from './registerModal';
import IclassOrder from './createIclassOrder';
import RemoveEquipHistory from './removeEquipmentHistory';

function RemoveEquipTable(props) {
    const [updateRows, setUpdateRows] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState(null);
    const [reloadRows, setReloadRows] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [statusId, setStatusId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [emailModal, setEmailModal] = useState(false);
    const [historyModal, setHistoryModal] = useState(false);
    const [orderStatus, setOrderStatus] = useState("")
    const [rut, setRut] = useState(null)
    const [modalContent, setModalContent] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [tableRows, setTableRows] = useState([])
    const [retryEmailID, setRetryEmailID] = useState(null);
    const endpoints = {
        columns: 'formerCustomers/remove_equipament/' + props.tableID + '/datatables_struct/',
        rows: 'formerCustomers/remove_equipament/' + props.tableID + '/datatables/',
        delete: 'formerCustomers/remove_equipament/',
        download: 'formerCustomers/remove_equipament/' + props.tableID + '/download_data/',
    }

    const modalContentOpc = [
        (<RegisterIclass close={setModalOpen} id={statusId} changeReload={() => setReloadRows(prevState => !prevState)} />),
        (<IclassOrder close={setModalOpen} id={statusId} changeReload={() => setReloadRows(prevState => !prevState)} />),
        (<div>
            <Form.Item label="Estado actual" style={{ width: "80%" }}>
                <Input placeholder="Por favor indique el estado en el que se encuentra" onChange={(e) => setOrderStatus(e.target.value)} />
            </Form.Item>
            <Button loading={loading} type="primary" onClick={() => getEmailData()}>Listo</Button>
        </div>),

    ]

    const customRendering = (text, value, id) => {
        switch (value) {
            case "created":
                return <Moment fromNow locale="es">{text}</Moment>;
            case "updated":
                return <Moment fromNow locale="es">{text}</Moment>;
            case "custom_change":
                return <div style={{ display: 'inline', color: 'rgb(24, 144, 255)', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setId(id); setShowModal(true) }}> {id}</div >
            case "custom_delete":
                return <Button type='primary' className="form-control" danger onClick={() => deleteRow(id)}>Borrar</Button>
            case "status":
                switch (text) {
                    case 0:
                        return <h4>Abierto</h4>
                    case 1:
                        return <h4>Cerrado</h4>
                    case 2:
                        return <h4>Cancelado</h4>
                }
            case "status_agendamiento":
                switch (text[0]) {
                    case 0:
                        return text[2] === 2 ? <h4>Cancelado</h4> : <Button type="primary" onClick={() => { setStatusId(id); setModalContent(1); setModalOpen(true) }}>Crear la orden en iclass</Button>
                    case 1:
                        return text[2] === 2 ? <h4>Cancelado</h4> : <Button type="primary" loading={loading} onClick={() => { setStatusId(id); setEmailModal(true); setOrderStatus("") }}>Se ha retirado el equipo</Button>
                    case 2:
                        return <h4>Se ha retirado el equipo</h4>
                    default:
                        if (text[1] == null) {
                            return text[2] === 2 ? <h4>Cancelado</h4> : <Button type="primary" onClick={() => { setId(id); setShowModal(true) }}>Tomar retiro</Button>
                        } else {
                            return text[2] === 2 ? <h4>Cancelado</h4> : <Button type="primary" onClick={() => { setStatusId(id); setModalContent(0); setModalOpen(true) }}>Registrar en iclass</Button>
                        }
                }
            case "status_storage":
                switch (text[0]) {
                    case 0:
                        return text[2] === 2 ? <h4>Cancelado</h4> : <Button type="primary" loading={loading} onClick={() => { updateStatus(id, 2, 1) }}>El equipo está en bodega</Button>
                    case 1:
                        return text[2] === 2 ? <h4>Cancelado</h4> : <Button type="primary" loading={loading} onClick={() => { updateStatus(id, 2, 2) }}>Procesado en iclass</Button>
                    case 2:
                        return <h4>Se ha procesado en iclass</h4>
                    case 3:
                        return <h4>Se han guardado los seriales</h4>
                    default:
                        if (text[1] == 2) {
                            return text[2] === 2 ? <h4>Cancelado</h4> : <Button type="primary" loading={loading} onClick={() => { updateStatus(id, 2, 0) }}>El equipo está en almacen</Button>
                        } else {
                            return <h4>No iniciado</h4>
                        }
                }
            case "history":
                return <Button type="primary" onClick={() => { setStatusId(id); setHistoryModal(true) }}>Ver el Historial</Button>
            default:
                break;
        }
    }

    useEffect(() => {
        if (id != null) setShowModal(true)
    }, [id])
    useEffect(() => {
        if (registerModal == false && showModal == false && updateRows) {
            setReloadRows(prevState => !prevState)
            setUpdateRows(false);
        }
    }, [registerModal, showModal])
    useEffect(() => {
        console.log("Cambio")
        if (modalContent != null) setModalOpen(true)
    }, [modalContent, statusId])

    const updateStatus = (id, section, status, person = null) => {
        setLoading(true)
        const formData = new FormData();
        switch (section) {
            case 0:
                formData.append("status", status)
                break;
            case 1:
                formData.append("status_agendamiento", status)
                break;
            case 2:
                formData.append("status_storage", status)
                break;
            default:
                break;
        }
        if (status == 0 && section == 3) {
            formData.append("current_status", orderStatus)
            person == null ? formData.append("person", rut) : formData.append("person", person)
        }
        HttpRequest.endpoint = "formerCustomers/remove_equipament/"
        HttpRequest.patch(id, formData).then((res) => {
            setLoading(false)
            if (res["ID"]) {
                if (section == 1 && status == 2) updateStatus(statusId, 3, "0", person)
                if (section == 3 && status == 0) updateStatus(statusId, 3, "1")
                setReloadRows(prevState => !prevState)
                Modal.success({
                    title: "Éxito",
                    content: "Se ha actualizado el estado correctamente"
                })
            } else {
                if (Object.values(res).includes('Invalid Email Template')) {
                    Modal.error({
                        title: "Error",
                        content: "No se ha seleccionado una plantilla de correo válida"
                    })
                }
                Modal.error({
                    title: "Error",
                    content: "Ha ocurrido un error al actualizar el estado"
                })
            }
        })
    }
    const getEmailData = () => {
        HttpRequest.endpoint = "formerCustomers/remove_equipament/" + statusId + "/"
        HttpRequest.get().then((res) => {
            setRut(res['rut'])
            updateStatus(statusId, 3, "0", res['rut'])
        })
    }

    const deleteRow = id => {
        if (can(removeEquipment.delete)) {
            Modal.confirm({
                title: '¿Está seguro que desea eliminar este registro?',
                icon: <ExclamationCircleOutlined />,
                cancelText: 'Cancelar',
                onOk() {
                    HttpRequest.endpoint = endpoints.delete;
                    HttpRequest.delete(id).then(res => {
                        if (res.status === 400) {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error eliminando este item'
                            })
                        } else {
                            Modal.success({
                                title: 'Éxito',
                                content: 'Se ha eliminado el item'
                            })
                            setReloadRows(prevState => !prevState)
                        }
                    })
                },
            });
        }
    }
    const retryEmail = e => {
        const formData = new FormData();
        formData.append("current_status", e.currentStatus)
        HttpRequest.endpoint = "formerCustomers/remove_equipament/" + retryEmailID + "/retry_email/"
        HttpRequest.post(formData).then((res) => {
            console.log(res)
            if (res["ID"]) {
                setReloadRows(prevState => !prevState)
            } else {
                Modal.error({
                    title: "Error",
                    content: "Ha ocurrido un error al reintentar el envío de correo"
                })
            }
        })
    }
    const retryMatrix = id => {
        HttpRequest.endpoint = "formerCustomers/remove_equipament/" + id + "/remove_from_matrix/";
        const formData = new FormData();
        HttpRequest.post(formData).then((res) => {
            if (res && Object.keys(res).length === 0) {
                setReloadRows(prevState => !prevState)
            } else {
                Modal.error({
                    title: "Error",
                    content: "Ha ocurrido un error al reintentar el retiro en Matrix"
                })
            }
        })
    }
    const removeFromNet = (id) => {
        const formData = new FormData();
        formData.append("removed_from_net", true);
        HttpRequest.endpoint = "formerCustomers/remove_equipament/"
        HttpRequest.patch(id, formData).then((res) => {
            if (res["ID"]) {
                setReloadRows(prevState => !prevState)
            } else {
                Modal.error({
                    title: "Error",
                    content: "Ha ocurrido un error al actualizar"
                })
            }

        })
    }

    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            email_sended: row.email_sended ? <h4>Se envió el email</h4> : row.status_storage[0] === 3 ? <Button type="primary" loading={loading} onClick={() => setRetryEmailID(row.ID)}>Reintentar</Button> : <p>NO</p>,
            removed_from_net: row.removed_from_net ? <p>SÍ</p> : row.status_agendamiento[0] === 2 ? <Button type="primary" loading={loading} onClick={() => removeFromNet(row.ID)}>Retirar</Button> : <p>NO</p>,
            removed_from_matrix: row.removed_from_net && row.status_storage[0] >= 1 ? row.removed_from_matrix ? <p>Retirado</p> : <Button type="primary" loading={loading} onClick={() => retryMatrix(row.ID)}>Reintentar</Button> : <p>NO</p>,
        })))
    }

    return (
        <>
            <Modal
                footer={null}
                visible={modalOpen}
                onCancel={() => {
                    setModalOpen(false);
                }}
                title={"Retiro de Equipo"}
                destroyOnClose={true}
                width={"70%"}
            >
                {modalContentOpc[modalContent]}
            </Modal>
            <Modal
                footer={null}
                visible={historyModal}
                onCancel={() => {
                    setHistoryModal(false);
                }}
                title={"Historial del Retiro de Equipo"}
                destroyOnClose={true}
                width={"70%"}
            >
                <RemoveEquipHistory id={statusId} />
            </Modal>
            <Modal
                footer={null}
                visible={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}
                title={"Editar Retiro de Equipo"}
                destroyOnClose={true}
                width={"70%"}
            >
                <RemoveEquipForm updateRows={() => setUpdateRows(true)} id={id} />
            </Modal>
            <Modal
                footer={null}
                visible={retryEmailID}
                onCancel={() => {
                    setRetryEmailID(null);
                }}
                title='Reintentar enviar email'
                destroyOnClose={true}
                width={"70%"}
            >
                <Form onFinish={retryEmail}>
                    <Form.Item name='currentStatus' label="Estado actual" rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Input placeholder="Por favor indique el estado en el que se encuentra" />
                    </Form.Item>
                    <Button className='center-btn' type='primary' htmlType='submit'>Reintentar</Button>
                </Form>
            </Modal>
            <FullTable
                modalContent={<RemoveEquipForm />}
                modalWidth={"70%"}
                endpoints={endpoints}
                tableName='Retiro Equipos'
                permissions={removeEquipment}
                customRendering={{ renderFunction: customRendering, keys: ['ID', 'status', 'status_agendamiento', 'status_storage', 'history', 'updated'] }}
                forceReload={reloadRows}
                buttons={[]}
                handleRows={handleRows}
                tableRows={tableRows}
            />
        </>
    )
}

export default RemoveEquipTable;