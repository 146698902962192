import React, { useState, useRef, useEffect } from 'react';
import HttpRequest from '../../services/HttpRequest';
import { Form, Button, Select, Radio, Modal, Row, Collapse, AutoComplete, Drawer, Tooltip } from 'antd';
import { connect } from 'react-redux';
import TestResultField from './TestResultField';
import SolutionResultField from './SolutionResultField';
import ProblemCategoryField from './ProblemCategoryField';
import { MessageOutlined } from '@ant-design/icons';
import TypifyComments from '../typify/typifyComments';
import { can } from '../../helpers/helpers';
import { escalation as escalationPermission } from '../../config/permissions';
import autoCloseModal from './../../components/modal/AutoCloseModal';
import Moment from "react-moment"
import moment from 'moment';
import Config from "../../config";
import errorModal from './../../components/modal/errorModal';
import MassiveFilters from './../massive/filtersForm';

function EscalationForm(props) {
    const [customerList, setCustomerList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [tests, setTests] = useState(null);
    const [testOptions, setTestOptions] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [id, setId] = useState(0);
    const [renderTests, setRenderTests] = useState([]);
    const [testsToSubmit, setTestsToSubmit] = useState({});
    const [escalation, setEscalation] = useState(null);
    const [solutions, setSolutions] = useState(null);
    const [renderSolutions, setRenderSolutions] = useState([]);
    const [solutionsToSubmit, setSolutionsToSubmit] = useState({});
    const [newEsc, setNewEsc] = useState(true)
    const [escID, setEscID] = useState(null);
    const [categories, setCategories] = useState({
        type: [],
        category: [],
        subcategory: [],
    });
    const [renderProblems, setRenderProblems] = useState([]);
    const [problemsToSubmit, setProblemsToSubmit] = useState({});
    const [agent, setAgent] = useState(null);
    const [agents, setAgents] = useState(null);
    const [selectedRut, setSelectedRut] = useState(false);
    const [userId, setUserId] = useState(null);
    const [showComments, setShowComments] = useState(false);
    const [userLevel, setUserLevel] = useState(null);
    const [showFilterServices, setShowFilterServices] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [multipleRuts, setMultipleRuts] = useState([]);
    const [filterServicesList, setFilterServicesList] = useState([]);
    const [multipleClients, setMultipleClients] = useState(false);

    const formRef = useRef();

    const searchRut = value => {
        setSelectedRut(false);
        if (value != null && value.length > 0) {
            setLoadingUsers(true);
            HttpRequest.endpoint = "matrix/customer_filter/";
            const formData = { criterion: formRef.current.getFieldsValue().criterion, search: value }
            HttpRequest.get(formData).then(res => {
                setCustomerList(res.map(rutObj => ({ value: rutObj.id, label: rutObj.text })))
            })
        } else {
            setCustomerList([]);
        }
    }
    const getServices = rut => {
        setSelectedRut(true);
        let list = [];
        const formData = { customer__rut: rut, operator: props.operator }
        HttpRequest.endpoint = "matrix/services/";
        HttpRequest.get(formData).then((res) => {
            res.forEach(element => {
                list.push(<Select.Option key={element["number"]} value={element["number"]}>{element["number"]}</Select.Option>)
            });
            setServiceList(list);
            if (list.length === 1) {
                formRef.current.setFieldsValue({ services: [res[0].number] })
            }
        })
    }
    const onKeyPress = e => {
        const specialCharRegex = new RegExp("^[0-9]*$");
        const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!specialCharRegex.test(pressedKey) && formRef.current.getFieldsValue().criterion === 'service') {
            e.preventDefault();
            return false;
        }
    }

    //TESTS

    const getTests = () => {
        HttpRequest.endpoint = 'escalation_ti/test/';
        HttpRequest.get({ operator: props.operator }).then(res => {
            if (res) {
                setTests(res)
                const options = [];
                res.forEach(test => {
                    options.push(<Select.Option key={test.ID} value={test.ID}>{test.name}</Select.Option>)
                })
                setTestOptions(options)
            }
        })
    }
    const addNewTest = () => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        const actualTests = [...renderTests];
        actualTests.push(
            <div key={id} className='dynamic-field'>
                <TestResultField index={id} testOptions={testOptions} tests={tests} addTest={addTest} />
                <Button danger type='primary' onClick={() => deleteTest(id)}>X</Button>
            </div>
        )
        setRenderTests(actualTests);
        setId(prevState => prevState + 1);
    }
    const addTest = test => {
        setTestsToSubmit(prevState => {
            const testObj = { ...prevState }
            testObj[test.index] = { id: test.id, result: test.result }
            return testObj;
        })
    }
    const deleteTest = (index, deleteId = false) => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        setTestsToSubmit(prevState => {
            const testCopy = { ...prevState };
            delete testCopy[index]
            return testCopy;
        })
        setRenderTests(prevState => {
            let indexToDelete;
            prevState.forEach((div, i) => {
                if (Number(div.key) === index) {
                    indexToDelete = i
                }
            })
            return prevState.slice(0, indexToDelete).concat(prevState.slice(indexToDelete + 1))
        });
        if (deleteId) {
            HttpRequest.endpoint = 'escalation_ti/escalation/' + props.id + '/remove_test/';
            const formData = new FormData();
            formData.append('test_id', deleteId)
            HttpRequest.post(formData).then(res => {
                if (Object.keys(res).length === 0) {
                    autoCloseModal('Se ha eliminado la prueba con éxito')
                    props.updateRows && props.updateRows()
                }
            })
        }
    }
    const generateOldTests = esc => {
        //const actualTests = [...renderTests];
        const actualTests = [];
        const testsToAdd = []
        esc.tests.forEach(test => {
            setId(prevId => {
                const editable = esc.status === 0 && esc.agent && esc.agent === userId;
                actualTests.push(
                    <div key={prevId} className='dynamic-field'>
                        <TestResultField disabled={!editable} initial={test} index={prevId} testOptions={testOptions} tests={tests} addTest={addTest} />
                        {editable && <Button danger type='primary' onClick={() => deleteTest(prevId, test.ID)}>X</Button>}
                    </div>
                )
                const prevTests = Object.values(testsToSubmit);
                let newTest = true;
                prevTests.forEach(obj => {
                    if (obj.id === test.test) {
                        newTest = false;
                    }
                })
                if (newTest) {
                    testsToAdd.push({
                        id: test.test,
                        result: test.result,
                        index: prevId
                    })
                }
                return prevId + 1
            })
        })
        testsToAdd.forEach(testObj => addTest(testObj))
        setRenderTests(actualTests);
    }

    //SOLUTIONS

    const getSolutions = () => {
        HttpRequest.endpoint = 'escalation_ti/solution/';
        HttpRequest.get({ operator: props.operator }).then(res => {
            const options = [];
            res.forEach(sol => {
                if (sol.operator === Number(props.operator)) {
                    options.push(sol)
                }
            })
            setSolutions(options)
        })
    }
    const addNewSolution = () => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        const actualSolutions = [...renderSolutions];
        actualSolutions.push(
            <div key={id} className='dynamic-field'>
                <SolutionResultField index={id} solutions={solutions} addSolution={addSolution} />
                <Button danger type='primary' onClick={() => deleteSolution(id)}>X</Button>
            </div>
        )
        setRenderSolutions(actualSolutions);
        setId(prevState => prevState + 1);
    }
    const addSolution = solution => {
        setSolutionsToSubmit(prevState => {
            const solutionObj = { ...prevState }
            solutionObj[solution.index] = { id: solution.id }
            return solutionObj;
        })
    }
    const deleteSolution = (index, deleteId = false) => {
        setSolutionsToSubmit(prevState => {
            const solutionCopy = { ...prevState };
            delete solutionCopy[index]
            return solutionCopy;
        })
        setRenderSolutions(prevState => {
            let indexToDelete;
            prevState.forEach((div, i) => {
                if (Number(div.key) === index) {
                    indexToDelete = i
                }
            })
            return prevState.slice(0, indexToDelete).concat(prevState.slice(indexToDelete + 1))
        });
        if (deleteId) {
            HttpRequest.endpoint = 'escalation_ti/escalation/' + props.id + '/remove_solution/';
            const formData = new FormData();
            formData.append('solution_id', deleteId)
            HttpRequest.post(formData).then(res => {
                if (res.ID) {
                    autoCloseModal('Se ha eliminado la solución con éxito')
                    props.updateRows && props.updateRows()
                }
            })
        }
    }
    const generateOldSolutions = escalation => {
        //const actualSolutions = [...renderSolutions];
        const actualSolutions = [];
        const solutionsToAdd = [];
        escalation.solutions.map(sol => {
            setId(prevId => {
                const editable = escalation.status === 0 && escalation.agent && escalation.agent === userId;
                actualSolutions.push(
                    <div key={prevId} className='dynamic-field'>
                        <SolutionResultField disabled={!editable} initial={sol} index={prevId} solutions={solutions} addSolution={addSolution} />
                        {editable && <Button danger type='primary' onClick={() => deleteSolution(prevId, sol)}>X</Button>}
                    </div>
                )
                //EVITAR QUE SE DUPLIQUEN
                const prevSols = Object.values(solutionsToSubmit);
                let newSol = true;
                prevSols.forEach(obj => {
                    if (obj.id === sol) {
                        newSol = false;
                    }
                })
                if (newSol) {
                    solutionsToAdd.push({
                        id: sol,
                        index: prevId
                    })
                }
                return prevId + 1
            })
        })
        solutionsToAdd.forEach(solObj => addSolution(solObj))
        setRenderSolutions(actualSolutions);
    }

    //ESCALATION

    const getEscalationInfo = () => {
        if (props.id && tests && testOptions && solutions && userId && categories.type) {
            setNewEsc(false);
            HttpRequest.endpoint = 'escalation_ti/escalation';
            HttpRequest.get(null, props.id).then(res => {
                res.agent = res.escalation_level === 0 ? res.agent_level_one : res.escalation_level === 1 ? res.agent_level_two : res.agent_level_tree;
                setEscalation(res)
                setId(0)
                generateOldTests(res)
                generateOldSolutions(res)
                generateOldProblems(res)
            })
        }
    }
    const submitEscalation = e => {
        const solutionArray = Object.values(solutionsToSubmit);
        const testArray = Object.values(testsToSubmit);
        const problemArray = Object.values(problemsToSubmit);
        const formData = new FormData();
        HttpRequest.endpoint = 'escalation_ti/escalation/'
        if (!props.id && newEsc) {
            if (!can(escalationPermission.create)) {
                return;
            }
            if (selectedRut || !e.rut) {
                setButtonLoading(true)
                formData.append("status", 0);
                formData.append("is_agent", true);
                e.services && e.services.length > 0 && formData.append("services", JSON.stringify(e.services))
                e.rut && e.criterion !== 'multiple' && formData.append("rut", JSON.stringify([e.rut]))
                e.criterion === 'multiple' && formData.append("rut", JSON.stringify(multipleRuts))
                HttpRequest.post(formData).then(res => {
                    if (res.ID) {
                        setEscID(res.ID);
                        setNewEsc(false);
                        res.agent = res.escalation_level === 0 ? res.agent_level_one : res.escalation_level === 1 ? res.agent_level_two : res.agent_level_tree;
                        setEscalation(res);
                        setButtonLoading(false);
                        autoCloseModal("Se ha creado el escalamiento con éxito")
                        props.updateRows && props.updateRows()
                    } else {
                        setButtonLoading(false);
                        Modal.error({ title: "Error", content: "Ha ocurrido un error creando el escalamiento." })
                    }
                })
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Debe seleccionar algún RUT de las opciones disponibles.'
                })
            }
        } else {
            if (!can(escalationPermission.edit)) {
                return;
            }
            setButtonLoading(true);
            const newTests = [];
            const newSolutions = [];
            const newProblems = [];
            const id = props.id || escID;
            HttpRequest.endpoint = 'escalation_ti/escalation';
            HttpRequest.get(null, id).then(res => {
                //TESTS
                const filteredTests = [];
                testArray.forEach((test, index) => {
                    let unique = true;
                    for (let i = index + 1; i < testArray.length; i++) {
                        if (test.id === testArray[i].id && test.result === testArray[i].result) {
                            unique = false;
                            break;
                        }
                    }
                    if (unique) {
                        filteredTests.push(test);
                    }
                })
                filteredTests.forEach(test => {  //si algun test fue modificado el 2do campo, aquí elimino y creo el nuevo
                    let newTest = true;
                    for (let i = 0; i < res.tests.length; i++) {
                        if (test.id === res.tests[i].test) {
                            newTest = false;
                            if (Number(test.result) !== res.tests[i].result) {
                                newTests.push(test)
                                HttpRequest.endpoint = 'escalation_ti/escalation/' + id + '/remove_test/';
                                const formData = new FormData();
                                formData.append('test_id', res.tests[i].ID)
                                HttpRequest.post(formData);
                            }
                            break;
                        }
                    }
                    if (newTest) {
                        newTests.push(test)
                    }
                })
                res.tests.forEach(oldTest => {  //si algun test fue reemplazado (el primer campo), aquí se elimina el viejo
                    let found = false;
                    for (let i = 0; i < filteredTests.length; i++) {
                        if (oldTest.test === filteredTests[i].id) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        HttpRequest.endpoint = 'escalation_ti/escalation/' + id + '/remove_test/';
                        const formData = new FormData();
                        formData.append('test_id', oldTest.ID)
                        HttpRequest.post(formData);
                    }
                })
                //SOLUTIONS
                const filteredSolutions = []
                solutionArray.forEach((sol, index) => {
                    let unique = true;
                    for (let i = index + 1; i < solutionArray.length; i++) {
                        if (sol.id === solutionArray[i].id) {
                            unique = false
                        }
                    }
                    if (unique) {
                        filteredSolutions.push(sol);
                    }
                })
                filteredSolutions.forEach(sol => {  //si alguna solucion fue modificado el 2do campo, aquí elimino y creo el nuevo
                    let newSolution = true;
                    for (let i = 0; i < res.solutions.length; i++) {
                        if (sol.id === res.solutions[i]) {
                            newSolution = false;
                        }
                    }
                    if (newSolution) {
                        newSolutions.push(sol.id)
                    }
                })
                res.solutions.forEach(oldSol => {  //si alguna solucion fue reemplazado (el primer campo), aquí se elimina el viejo
                    let found = false;
                    for (let i = 0; i < filteredSolutions.length; i++) {
                        if (oldSol === filteredSolutions[i].id) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        HttpRequest.endpoint = 'escalation_ti/escalation/' + id + '/remove_solution/';
                        const formData = new FormData();
                        formData.append('solution_id', oldSol)
                        HttpRequest.post(formData);
                    }
                })
                //PROBLEMS
                const filteredProblems = [];
                problemArray.forEach((prob, index) => {
                    let unique = true;
                    for (let i = index + 1; i < problemArray.length; i++) {
                        if (prob.id === problemArray[i].id) {
                            unique = false
                        }
                    }
                    if (unique) {
                        filteredProblems.push(prob);
                    }
                })
                filteredProblems.forEach(prob => {  //si algun problem fue modificado el 2do campo, aquí elimino y creo el nuevo
                    let newProblem = true;
                    for (let i = 0; i < res.problems.length; i++) {
                        if (prob.id === res.problems[i]) {
                            newProblem = false;
                        }
                    }
                    if (newProblem) {
                        newProblems.push(prob.id)
                    }
                })
                res.problems.forEach(oldProb => {  //si algun problem fue reemplazado (el primer campo), aquí se elimina el viejo
                    let found = false;
                    for (let i = 0; i < filteredProblems.length; i++) {
                        if (oldProb === filteredProblems[i].id) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        HttpRequest.endpoint = 'escalation_ti/escalation/' + id + '/remove_problem/';
                        const formData = new FormData();
                        formData.append('problem_id', oldProb)
                        HttpRequest.post(formData);
                    }
                })
                Promise.all(newTests.map(newTest => {
                    HttpRequest.endpoint = 'escalation_ti/escalation/' + id + '/add_test/';
                    const data = new FormData();
                    data.append('test', newTest.id);
                    data.append('result', newTest.result)
                    return HttpRequest.post(data)
                })).then(allRes => {
                    console.log('tests', allRes)
                    let error = false;
                    allRes.forEach(testRes => {
                        if (!testRes.ID) {
                            error = true
                        }
                    })
                    Promise.all(newSolutions.map(newSol => {
                        HttpRequest.endpoint = 'escalation_ti/escalation/' + id + '/add_solution/';
                        const data = new FormData();
                        data.append('solution_id', newSol);
                        return HttpRequest.post(data)
                    })).then(allRes2 => {
                        console.log('solutions', allRes2)
                        allRes2.forEach(solRes => {
                            if (!solRes.ID) {
                                error = true
                            }
                        })
                        Promise.all(newProblems.map(newProb => {
                            HttpRequest.endpoint = 'escalation_ti/escalation/' + id + '/add_problem/';
                            const data = new FormData();
                            data.append('problem_id', newProb);
                            return HttpRequest.post(data)
                        })).then(allRes3 => {
                            console.log('problems', allRes3)
                            allRes3.forEach(probRes => {
                                if (!probRes.ID) {
                                    error = true
                                }
                            })
                            setButtonLoading(false);
                            if (!error) {
                                autoCloseModal("Se ha editado el escalamiento con éxito")
                                props.updateRows && props.updateRows()
                            } else {
                                Modal.error({
                                    title: 'Error',
                                    content: 'Ha ocurrido un error editando 1 o más tests, soluciones o problemas. Por favor intente nuevamente'
                                })
                            }
                        })
                    })
                })
            })
        }
    }
    const escalateLevel = () => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        setButtonLoading(true)
        HttpRequest.endpoint = `escalation_ti/escalation/${props.id || escalation.ID}/escalate/`
        HttpRequest.post().then(res => {
            if (res.ID) {
                autoCloseModal('Se ha escalado a un nivel superior con éxito')
                props.updateRows && props.updateRows()
                res.agent = res.escalation_level === 0 ? res.agent_level_one : res.escalation_level === 1 ? res.agent_level_two : res.agent_level_tree;
                setEscalation(res);
                setId(0);
                generateOldTests(res);
                generateOldSolutions(res);
                generateOldProblems(res);
                setButtonLoading(false)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error escalando a un nivel superior'
                })
                setButtonLoading(false)
            }
        })
    }
    const deescalateLevel = () => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        setButtonLoading(true)
        HttpRequest.endpoint = `escalation_ti/escalation/${props.id || escalation.ID}/deescalate/`
        HttpRequest.post().then(res => {
            if (res.ID) {
                autoCloseModal('Se ha deescalado con éxito')
                props.updateRows && props.updateRows()
                res.agent = res.escalation_level === 0 ? res.agent_level_one : res.escalation_level === 1 ? res.agent_level_two : res.agent_level_tree;
                setEscalation(res);
                setId(0);
                generateOldTests(res);
                generateOldSolutions(res);
                generateOldProblems(res);
                setButtonLoading(false)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error deescalando'
                })
                setButtonLoading(false)
            }
        })
    }
    const returnSac = () => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        setButtonLoading(true)
        HttpRequest.endpoint = `escalation_ti/escalation/`
        const formData = new FormData();
        formData.append('status', 1)
        HttpRequest.patch(escalation.ID, formData).then(res => {
            if (res.ID) {
                res.agent = res.escalation_level === 0 ? res.agent_level_one : res.escalation_level === 1 ? res.agent_level_two : res.agent_level_tree;
                setEscalation(res);
                setId(0);
                generateOldTests(res);
                generateOldSolutions(res);
                generateOldProblems(res);
                autoCloseModal('Se ha devuelto a SAC con éxito')
                props.updateRows && props.updateRows()
                setButtonLoading(false)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error devolviendo a SAC'
                })
            }
        })
    }
    const closeEscalation = () => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        setButtonLoading(true)
        HttpRequest.endpoint = `escalation_ti/escalation/`
        const formData = new FormData();
        formData.append('status', 2)
        HttpRequest.patch(escalation.ID, formData).then(res => {
            if (res.ID) {
                res.agent = res.escalation_level === 0 ? res.agent_level_one : res.escalation_level === 1 ? res.agent_level_two : res.agent_level_tree;
                setEscalation(res);
                setId(0);
                generateOldTests(res);
                generateOldSolutions(res);
                generateOldProblems(res);
                autoCloseModal('Se ha cerrado el escalamiento con éxito')
                props.updateRows && props.updateRows()
                setButtonLoading(false)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error cerrando el escalamiento'
                })
                setButtonLoading(false)
            }
        })
    }
    const editAgent = () => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        setButtonLoading(true)
        HttpRequest.endpoint = 'escalation_ti/escalation/';
        const agentData = new FormData();
        const strAgent = userLevel === 2 ? 'agent_level_tree' : userLevel === 1 ? 'agent_level_two' : 'agent_level_one'
        agentData.append(strAgent, agent);
        agentData.append('escalation_level', userLevel);
        const id = props.id || escID;
        HttpRequest.patch(id, agentData).then(res => {
            if (res.ID) {
                res.agent = res.escalation_level === 0 ? res.agent_level_one : res.escalation_level === 1 ? res.agent_level_two : res.agent_level_tree;
                setEscalation(res);
                setId(0);
                generateOldTests(res);
                generateOldSolutions(res);
                generateOldProblems(res);
                autoCloseModal('Se ha modificado el agente con éxito')
                props.updateRows && props.updateRows()
                setButtonLoading(false)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error modificando el agente'
                })
            }
        })
    }

    //PROBLEMS

    const getTypeCategories = () => {
        HttpRequest.endpoint = 'escalation_ti/problems/';
        HttpRequest.get({ operator: props.operator, classification: 1 }).then(res => {
            const typeCategories = [];
            res.forEach(cat => {
                typeCategories.push(<Select.Option key={cat.ID} value={cat.ID}>{cat.name}</Select.Option>)
            })
            setCategories(prevCats => ({
                ...prevCats,
                type: typeCategories
            }));
        })
    }
    const addNewProblem = () => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        const actualProblems = [...renderProblems];
        actualProblems.push(
            <div key={id} className='dynamic-field'>
                <ProblemCategoryField index={id} typeCategories={categories.type} addProblem={addProblem} />
                <Button danger type='primary' onClick={() => deleteProblem(id)}>X</Button>
            </div>
        )
        setRenderProblems(actualProblems);
        setId(prevState => prevState + 1);
    }
    const addProblem = prob => {
        setProblemsToSubmit(prevState => {
            const problemObj = { ...prevState }
            problemObj[prob.index] = { id: prob.id }
            return problemObj;
        })
    }
    const deleteProblem = (index, deleteId = false) => {
        setProblemsToSubmit(prevState => {
            const problemCopy = { ...prevState };
            delete problemCopy[index]
            return problemCopy;
        })
        setRenderProblems(prevState => {
            let indexToDelete;
            prevState.forEach((div, i) => {
                if (Number(div.key) === index) {
                    indexToDelete = i
                }
            })
            return prevState.slice(0, indexToDelete).concat(prevState.slice(indexToDelete + 1))
        });
        if (deleteId) {
            HttpRequest.endpoint = 'escalation_ti/escalation/' + props.id + '/remove_problem/';
            const formData = new FormData();
            formData.append('problem_id', deleteId)
            HttpRequest.post(formData).then(res => {
                if (res.ID) {
                    autoCloseModal('Se ha eliminado el problema con éxito')
                    props.updateRows && props.updateRows()
                }
            })
        }
    }
    const generateOldProblems = escalation => {
        //const actualProblems = [...renderProblems];
        const actualProblems = [];
        const probsToAdd = []
        escalation.problems.map(prob => {
            setId(prevId => {
                const editable = escalation.status === 0 && escalation.agent && escalation.agent === userId;
                actualProblems.push(
                    <div key={prevId} className='dynamic-field'>
                        <ProblemCategoryField disabled={!editable} initial={prob} index={prevId} typeCategories={categories.type} addProblem={addProblem} />
                        {editable && <Button danger type='primary' onClick={() => deleteProblem(prevId, prob)}>X</Button>}
                    </div>
                )
                const prevProbs = Object.values(problemsToSubmit);
                let newProb = true;
                prevProbs.forEach(obj => {
                    if (obj.id === prob) {
                        newProb = false;
                    }
                })
                if (newProb) {
                    probsToAdd.push({
                        id: prob,
                        index: prevId
                    })

                }
                return prevId + 1
            })
        })
        probsToAdd.forEach(probObj => addProblem(probObj))
        setRenderProblems(actualProblems);
    }

    const getUserId = () => {
        const user = JSON.parse(localStorage.getItem('userInfo'))
        setUserId(user.ID)
        let userLev;
        if (user.groups.includes("Agente TI nivel tres")) {
            userLev = 2
        } else if (user.groups.includes("Agente TI nivel dos")) {
            userLev = 1
        } else {
            userLev = 0
        }
        setUserLevel(userLev)
        getAgents(userLev);
    }

    const isSuperiorAgent = () => {
        let superior = false
        const user = JSON.parse(localStorage.getItem('userInfo'))
        if (user.groups.includes("Agente TI nivel tres") && escalation.escalation_level < 2) {
            superior = true
        } else if (user.groups.includes("Agente TI nivel dos") && escalation.escalation_level < 1) {
            superior = true
        }
        return superior
    }

    const takeTicket = () => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        setButtonLoading(true);
        HttpRequest.endpoint = `escalation_ti/escalation/`
        const formData = new FormData();
        const userAgentLevel = userLevel === 2 ? 'agent_level_tree' : userLevel === 1 ? 'agent_level_two' : 'agent_level_one'
        formData.append(userAgentLevel, userId);
        formData.append('escalation_level', userLevel);
        HttpRequest.patch(escalation.ID, formData).then(res => {
            if (res.ID) {
                res.agent = res.escalation_level === 0 ? res.agent_level_one : res.escalation_level === 1 ? res.agent_level_two : res.agent_level_tree;
                setEscalation(res);
                setId(0);
                generateOldTests(res);
                generateOldSolutions(res);
                generateOldProblems(res);
                autoCloseModal('Has tomado el ticket')
                props.updateRows && props.updateRows();
                setButtonLoading(false)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error tomando el ticket'
                })
                setButtonLoading(false)
            }
        })
    }
    const getAgents = level => {
        HttpRequest.endpoint = 'user/data/information_group/';
        const payload = new FormData();
        const strLevel = level === 2 ? "Agente TI nivel tres" : level === 1 ? "Agente TI nivel dos" : "Agente TI nivel uno"
        payload.append("group_name", strLevel)
        HttpRequest.get({ group_name: strLevel }).then(res => {
            setAgents(res)
        })
    }
    const cancelEscalation = () => {
        if (!can(escalationPermission.edit)) {
            return;
        }
        setButtonLoading(true)
        HttpRequest.endpoint = `escalation_ti/escalation/`;
        const formData = new FormData();
        formData.append('status', 3);
        HttpRequest.patch(escalation.ID, formData).then(res => {
            if (res.ID) {
                res.agent = res.escalation_level === 0 ? res.agent_level_one : res.escalation_level === 1 ? res.agent_level_two : res.agent_level_tree;
                setEscalation(res);
                setId(0);
                generateOldTests(res);
                generateOldSolutions(res);
                generateOldProblems(res);
                autoCloseModal('Se ha cancelado el escalamiento con éxito')
                props.updateRows && props.updateRows();
                setButtonLoading(false)
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Ha ocurrido un error cancelando el ticket'
                })
                setButtonLoading(false)
            }
        })
    }

    let ticketUserLevAvailable = true;
    if (escalation) {
        if ((userLevel === 0 && escalation.agent_level_one !== null && escalation.agent_level_one !== userId) || (userLevel === 1 && escalation.agent_level_two !== null && escalation.agent_level_two !== userId) || userLevel === 2 && escalation.agent_level_tree !== null && escalation.agent_level_tree !== userId) {
            ticketUserLevAvailable = false
        }
    }
    const handleMultipleClients = () => {
        setShowFilterServices(true)
        setMultipleClients(true)
    }
    const handleOneClient = () => {
        setMultipleClients(false)
        formRef.current.setFieldsValue({
            services: []
        })
    }

    const getFilters = (values, filtros) => {
        setFilterLoading(true)
        fetch(Config.API_URL + '/matrix/services-filters-dict/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("authorization")}`,
            },
            body: JSON.stringify({
                criterion: values.typeFilter.value,
                filters: filtros,
                unpaid_ballot: values.unpaid_ballot ? true : false,
                excludes: [],
                includes: []
            })
        }).then(res => res.json()).then(res => {
            setFilterLoading(false)
            if (res && typeof res === 'object') {
                const list = []
                for (let key in res) {
                    list.push(<Select.Option key={key} value={key}>{key}</Select.Option>)
                }
                setMultipleRuts(Object.values(res));
                setServiceList(list);
                formRef.current.setFieldsValue({
                    services: Object.keys(res)
                })
                setFilterServicesList(res)
                setShowFilterServices(false);
            } else {
                errorModal('Ha ocurrido un error consultando los servicios según los filtros')
            }
        })
    }
    const handleMultipleServices = services => {
        const rutList = [];
        services.forEach(serv => {
            if (serv in filterServicesList) {
                rutList.push(filterServicesList[serv])
            }
        })
        setMultipleRuts(rutList)
    }

    useEffect(getUserId, [])
    useEffect(getTests, [])
    useEffect(getSolutions, [])
    useEffect(getTypeCategories, []);
    useEffect(getEscalationInfo, [testOptions, solutions, userId])

    return (
        <>
            <Form ref={formRef} name='escalation' initialValues={{ criterion: 'service' }} onFinish={submitEscalation}>
                <Collapse defaultActiveKey={['1']}>
                    <Collapse.Panel header='Resolución' key='1' className='relative-pos'>
                        {!props.id && !escalation &&
                            <>
                                <Form.Item name='criterion'>
                                    <Radio.Group onChange={e => e.target.value === 'multiple' ? handleMultipleClients() : handleOneClient()} className="radio-group">
                                        <Radio value="rut">Rut</Radio>
                                        <Radio value="name">Nombre</Radio>
                                        <Radio value="composite_address">Dirección</Radio>
                                        <Radio value="service">Servicio</Radio>
                                        <Radio value='multiple'>Multiples clientes</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {!multipleClients && <Form.Item name='rut' label='RUT (Seleccionar)'>
                                    {/*<Select onKeyPress={onKeyPress} onChange={getServices} placeholder="Seleccionar" showSearch onSearch={searchRut} >
                                        {customerList.map(cust => <Select.Option key={cust.id} value={cust.id}>{cust.text}</Select.Option>)}
                                    </Select>*/}
                                    <AutoComplete onKeyPress={onKeyPress} options={customerList} onSearch={rut => searchRut(rut)} onSelect={(rut) => getServices(rut)} placeholder="Seleccione un RUT" />
                                </Form.Item>}
                                {multipleClients && <Row justify='center'><Button onClick={() => setShowFilterServices(true)} type='primary'>Buscar múltiples clientes</Button></Row>}
                                <Form.Item name='services' label='Servicios'>
                                    <Select placeholder="Seleccionar" mode="multiple" onChange={services => handleMultipleServices(services)}>
                                        {serviceList}
                                    </Select>
                                </Form.Item>
                            </>
                        }
                        {!props.id && !escalation && <Button htmlType='submit' type='primary' className='center-btn' loading={buttonLoading}>Crear</Button>}
                        {escalation && <Tooltip title="Comentarios">
                            <Button className='center-btn' style={{ 'marginBottom': '10px' }} shape="circle" icon={<MessageOutlined />} onClick={() => setShowComments(true)} />
                        </Tooltip>}
                        {escalation && <h5 className='center-text'>Status: {escalation.status === 0 ? 'Abierto' : escalation.status === 1 ? 'En SAC' : escalation.status === 2 ? 'Cerrado' : 'Cancelado'}</h5>}
                        {escalation && <h5 className='center-text'>Nivel de escalamiento: {escalation.escalation_level === 0 ? 'Primero' : escalation.escalation_level === 1 ? 'Segundo' : 'Tercero'}</h5>}
                        {escalation && <h5 className='center-text'>SLA: Hace {<Moment fromNow ago locale='en'>{moment().add(-1 * escalation.SLA, 's')}</Moment>}</h5>}
                        {escalation && !escalation.agent && userId && userLevel !== null && userLevel >= escalation.escalation_level && escalation.status === 0 && ticketUserLevAvailable && <Button onClick={takeTicket} type='primary' className='center-btn take-ticket' loading={buttonLoading}>Tomar ticket</Button>}
                        <Row className='center-row'>
                            {escalation && escalation.status === 0 && escalation.typify && escalation.agent === userId && <Button onClick={returnSac} loading={buttonLoading}>Devolver a SAC</Button>}
                            {escalation && escalation.escalation_level < 2 && escalation.status === 0 && escalation.agent === userId && <Button onClick={escalateLevel} loading={buttonLoading}>Escalar a nivel superior</Button>}
                            {escalation && escalation.escalation_level > 0 && escalation.status === 0 && escalation.agent === userId && <Button onClick={deescalateLevel} loading={buttonLoading}>Deescalar</Button>}
                            {escalation && escalation.status === 0 && !escalation.typify && escalation.agent === userId && <Button onClick={closeEscalation} loading={buttonLoading}>Cerrar escalamiento</Button>}
                            {escalation && escalation.status === 0 && !escalation.typify && (escalation.agent === userId || isSuperiorAgent()) && <Button onClick={cancelEscalation} loading={buttonLoading}>Cancelar escalamiento</Button>}
                            {escalation && escalation.status === 0 && agents && escalation.agent === userId &&
                                <Select defaultValue={escalation.agent} placeholder='Seleccionar agente' onChange={e => setAgent(e)}>
                                    {agents.map(ag => <Select.Option value={ag.ID} key={ag.ID} disabled={ag.ID === userId}>{ag.name}</Select.Option>)}
                                </Select>
                            }
                        </Row>
                        {escalation && escalation.status === 0 && escalation.agent === userId && agent && <Button onClick={editAgent} type='primary' className='center-btn' loading={buttonLoading}>Asignar agente</Button>}
                        <Drawer title="Basic Drawer" placement="right" width='380px' closable={false} onClose={() => setShowComments(false)} visible={showComments} >
                            {escalation && escalation.commentaries && <TypifyComments id={escalation.commentaries} />}
                        </Drawer>
                    </Collapse.Panel>
                    {escalation && <Collapse.Panel header='Pruebas' key='2'>
                        {(escalation && escalation.status === 0 && escalation.agent && escalation.agent === userId) && <Button onClick={addNewTest} className='center-btn add-filter-btn'>Agregar prueba</Button>}
                        {renderTests}
                        {(escalation && escalation.status === 0 && escalation.agent && escalation.agent === userId) && <Button htmlType='submit' type='primary' className='center-btn' loading={buttonLoading}>Guardar</Button>}
                    </Collapse.Panel>}
                    {escalation && <Collapse.Panel header='Soluciones' key='3'>
                        {escalation && escalation.status === 0 && escalation.agent && escalation.agent === userId && <Button onClick={addNewSolution} className='center-btn add-filter-btn'>Agregar solución</Button>}
                        {renderSolutions}
                        {(escalation && escalation.status === 0 && escalation.agent && escalation.agent === userId) && <Button htmlType='submit' type='primary' className='center-btn' loading={buttonLoading}>Guardar</Button>}
                    </Collapse.Panel>}
                    {escalation && <Collapse.Panel header='Problemas' key='4'>
                        {escalation && escalation.status === 0 && escalation.agent && escalation.agent === userId && <Button onClick={addNewProblem} className='center-btn add-filter-btn'>Agregar problema</Button>}
                        {renderProblems}
                        {(escalation && escalation.status === 0 && escalation.agent && escalation.agent === userId) && <Button htmlType='submit' type='primary' className='center-btn' loading={buttonLoading}>Guardar</Button>}
                    </Collapse.Panel>}
                </Collapse>
            </Form>
            {showFilterServices &&
                <Modal width={600} visible={showFilterServices} footer={null} onCancel={() => setShowFilterServices(false)} title='Multiples clientes'>
                    <MassiveFilters loading={filterLoading} getFilters={getFilters} filtros={[]} tipify closeModal={() => setShowFilterServices(false)} />
                </Modal>
            }
        </>
    )
}


function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(EscalationForm);