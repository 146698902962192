import React, { useEffect, useState } from "react";
import { Card, Timeline } from "antd";
import HttpRequest from "../../services/HttpRequest";
import Moment from "react-moment";

const RemoveEquipHistory = (props) => {

    const [first, setFirst] = useState();
    const [changes, setChanges] = useState([])
    const [agentList, setAgentList] = useState();
    const [firstDate, setFirstDate] = useState();

    let statusLabel = { 0: "Abierto", 1: "Cerrado", 2: "Cancelado" }
    let statustiLabel = { 0: "Correo enviado", 1: "Retirado de Matrix", 3: "Retirado de red" }
    let statusSacLabel = { 0: "Registrado en Iclass", 1: "Orden creada", 2: "Retirar Orden" }
    let statusStorageLabel = { 0: "En almacén", 1: "En bodega", 2: "Procesar en iclass" }
    let keysLabel = {
        "rut": "Rut del cliente", "service": "Servicio", "serial": "Serial", "iclass_id": "ID de Iclass",
        "status": "Estado", "previous_state": "Estado anterior", "status_agendamiento": "Estado SAC", "status_storage": "Estado Almacen", "status_ti": "Estado TI",
        "agent_storage": "Agente Terreno", "agent_ti": 'Agente TI', "agent_sac": 'Agente SAC'
    }


    const getHistory = (id) => {
        let changeList = [];
        HttpRequest.endpoint = "formerCustomers/remove_equipament/" + id + "/history/";
        HttpRequest.get().then((res) => {
            setFirst(res["first"]);
            setFirstDate(
                <div>
                    <Moment format="DD/MM/YYYY hh:mm">
                        {res["first"]["created"]}
                    </Moment>
                    <p>{agentList[res["first"]["creator"]]}</p>
                </div>)
            res["changes"].forEach(element => {
                if (Object.keys(element).includes("created") || element.length <= 2) {
                    return;
                };
                let label = (
                    <div>
                        <Moment format="DD/MM/YYYY hh:mm">
                            {element["updated"]["new_value"]}
                        </Moment>
                        <p>{agentList[element["updater"]["new_value"]]}</p>
                    </div>);

                let list = [];
                Object.keys(element).forEach(key => {
                    if (key == "updater" || key == "updated") {
                        return;
                    }
                    list.push(<div>
                        <h5>{keysLabel[key]}</h5>
                        Valor nuevo:
                        {{
                            status_ti: " " + statustiLabel[element[key]["new_value"]],
                            status: " " + statusLabel[element[key]["new_value"]],
                            previous_state: " " + statusLabel[element[key]["new_value"]],
                            status_agendamiento: " " + statusSacLabel[element[key]["new_value"]],
                            status_storage: " " + statusStorageLabel[element[key]["new_value"]],
                            agent_sac: " " + agentList[element[key]["new_value"]],
                            agent_storage: " " + agentList[element[key]["new_value"]],
                            agent_ti: " " + agentList[element[key]["new_value"]],
                            serial: " " + element[key]["new_value"],
                            iclass_id: " " + element[key]["new_value"]
                        }[key]}
                        <br />
                        <br />
                    </div>)
                });
                if (list.length > 0) {
                    changeList.push(
                        < Timeline.Item label={label}>
                            <Card bordered={true} >
                                {list}
                            </Card>
                        </Timeline.Item>);
                }

            });
            setChanges(changeList)
        })
    }

    const getAgents = () => {
        let list = {};
        HttpRequest.endpoint = "user/data/information/";
        HttpRequest.get().then((res) => {
            res.forEach(element => {
                list[element["ID"]] = element["name"]
            });
            setAgentList(list);
        })
    }


    useEffect(() => {
        getAgents();
    }, [])

    useEffect(() => {
        if (agentList && props.id) getHistory(props.id);
    }, [agentList, props.id])

    return (
        <div>
            {first && (
                <Timeline mode="alternate">
                    <Timeline.Item label={firstDate}>
                        <Card title="Creado" bordered={true} style={{ width: 300 }} >
                            <p>Servicios {first["service"]}<br />
                            Serial: {first["serial"]}<br />
                            Rut del cliente: {first["rut"]}<br />
                            Estado: {statusLabel[first["status"]]}<br />
                            Agente SAC: {agentList[first["agent_sac"]]}<br />
                            Agente Terreno: {agentList[first["agent_storage"]]}<br />
                            Agente TI: {agentList[first["agent_ti"]]}<br /></p>
                        </Card>
                    </Timeline.Item>
                    {changes}
                </Timeline >
            )}


            {/* <Timeline.Item>Solve initial network problems 2015-09-01</Timeline.Item>
            <Timeline.Item>Technical testing 2015-09-01</Timeline.Item>
            <Timeline.Item>Network problems being solved 2015-09-01</Timeline.Item> */}
        </div>
    );


}

export default RemoveEquipHistory;