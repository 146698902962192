import React, { useState, useEffect } from "react";
//import './EmailDetails.css';
import HttpRequest from "./../../services/HttpRequest";
import { Button, Timeline, Input, Form, Select, Row } from "antd";
import Moment from "react-moment";
import autoCloseModal from "./../../components/modal/AutoCloseModal";
import errorModal from "./../../components/modal/errorModal";

function EmailDetails(props) {
	const [chats, setChats] = useState([]);
	const [templates, setTemplates] = useState([]);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [answer, setAnswer] = useState(null);

	const submit = (values) => {
		//setButtonLoading(true)
		HttpRequest.endpoint =
			answer === "template"
				? `communications/email/send_email/`
				: `communications/email/`;
		const formData = new FormData();
		answer === "template" &&
			formData.append("service_number", values.service);
		answer === "template" && formData.append("template", values.template);
		answer === "normal" && formData.append("message", values.text);
		formData.append("chat", props.details.chat);
		formData.append("sender", values.sender);
		formData.append("receiver", values.receiver);
		formData.append("subject", values.subject);
		HttpRequest.post(formData).then((res) => {
			setButtonLoading(false);
			if (res && res.ID) {
				autoCloseModal("Se ha respondido con éxito");
			} else {
				errorModal("Ha ocurrido un error respondiendo el correo");
			}
		});
	};

	useEffect(() => {
		if (props.details) {
			HttpRequest.endpoint = `communications/email/${props.details.ID}/chat_emails/`;
			HttpRequest.get().then((res) => {
				console.log(res);
				//setChats(res)
			});
		}
	}, [props.details]);

	useEffect(() => {
		if (props.details) {
			HttpRequest.endpoint = `communications/template_email/`;
			HttpRequest.get().then((res) => {
				if (res && Array.isArray(res)) {
					setTemplates(res);
				}
			});
		}
	}, [props.details]);

	if (templates.length === 0 /*|| chats.length === 0*/) {
		return null;
	}

	return (
		<>
			<Timeline mode="alternate">
				{props.details && (
					<Timeline.Item key="original">
						{props.details.sender} el{" "}
						<strong>
							{
								<Moment format="DD/MM/YYYY HH:mm" locale="es">
									{props.details.created}
								</Moment>
							}
							:{" "}
						</strong>
						<Button
							onClick={() =>
								props.setTemplate(props.details.message)
							}
						>
							Ver mensaje
						</Button>
					</Timeline.Item>
				)}
				{chats &&
					Array.isArray(chats) &&
					chats.map((chat, i) => (
						<Timeline.Item key={i}>
							{chat.sender} el{" "}
							<strong>
								{
									<Moment
										format="DD/MM/YYYY HH:mm"
										locale="es"
									>
										{chat.created}
									</Moment>
								}
								:{" "}
							</strong>
							<Button
								onClick={() => props.setTemplate(chat.message)}
							>
								Ver mensaje
							</Button>
						</Timeline.Item>
					))}
			</Timeline>
			{props.details.chat && (
				<>
					<h1 className="center-text">
						Seleccione como desea responder el email
					</h1>
					<Row justify="center">
						<Button onClick={() => setAnswer("normal")}>
							Responder directamente
						</Button>
						<Button onClick={() => setAnswer("template")}>
							Responder con plantilla
						</Button>
					</Row>
					<Form
						onFinish={submit}
						initialValues={{
							sender: props.details.sender,
							receiver: props.details.receiver,
						}}
					>
						<Form.Item
							name="sender"
							label="Emisor"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
								{
									type: "email",
									message: "Formato de email inválido",
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="receiver"
							label="Recipiente"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
								{
									type: "email",
									message: "Formato de email inválido",
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="subject"
							label="Asunto"
							rules={[
								{
									required: true,
									message: "Este campo es requerido",
								},
							]}
						>
							<Input />
						</Form.Item>
						{answer === "normal" && (
							<Form.Item
								name="text"
								label="Contenido"
								rules={[
									{
										required: true,
										message: "Este campo es requerido",
									},
								]}
							>
								<Input.TextArea />
							</Form.Item>
						)}
						{answer === "template" && (
							<>
								<Form.Item
									name="template"
									label="Plantilla"
									rules={[
										{
											required: true,
											message: "Este campo es requerido",
										},
									]}
								>
									<Select>
										{templates.map((temp) => (
											<Select.Option
												key={temp.ID}
												value={temp.ID}
											>
												{temp.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item
									name="service"
									label="Servicio"
									rules={[
										{
											required: true,
											message: "Este campo es requerido",
										},
									]}
								>
									<Input />
								</Form.Item>
							</>
						)}
						<Button
							disabled={!answer}
							loading={buttonLoading}
							htmlType="submit"
							type="primary"
							className="center-btn"
						>
							Responder
						</Button>
					</Form>
				</>
			)}
		</>
	);
}

export default EmailDetails;
