import React from "react";
import { Tabs } from "antd";
import TypificationsTable from "../../pages/TypificationsTables/TypificationsTables";
import Categories from "../../pages/category/Categories";
import TypificationsSlack from "../../pages/typifications/TypificationsSlack";
import { canWithoutMessage, canGroup } from "../../helpers/helpers";
import permissions from "../../config/permissions";
import EscalationCustomTables from "../../pages/escalations/EscalationCustomTables";
import EscalationProblems from "../../pages/escalations/EscalationProblems";
import EscalationSlack from "../../pages/escalations/EscalationSlack";
import EscalationTests from "../../pages/escalations/EscalationTests";
import EscalationResults from "../../pages/escalations/EscalationResults";
import RemoveEquipCustomTables from "../../pages/removeEquipment/removeEquipCustomTables";
import RemoveEquipEmailTemplate from "../../pages/removeEquipment/RemoveEquipEmailTemplate";
import RemoveEquipmentChannel from "../../pages/removeEquipment/RemoveEquipmentChannel";
import WizardConfigurationsTable from "../../pages/WizardConfigurations/WizardConfigurationsTable";
import LeadCustomTables from "../../pages/leads/LeadCustomTables";
import LeadletCustomTables from "../../pages/leadlet/LeadletCustomTables";
import EmailCustomTables from "./../../pages/emails/EmailCustomTables";
import IndicadoresTable from "./../../pages/qc/IndicadoresTable";
import CanalesTable from "./../../pages/qc/CanalesTable";
import InspeccionesCustomTables from "./../../pages/qc/InspeccionesCustomTables";
import WhatsappCustomTables from "./../../pages/whatsapp/WhatsappCustomTable";
import TextCustomTables from "./../../pages/textMessages/TextCustomTable";
import CallsCustomTables from "./../../pages/calls/CallsCustomTable";
import SacTestTable from "./../../pages/escalations/SacTestTable";
import TicketsCustomTables from "./../../pages/tickets/TicketsCustomTable";
import IclassCustomTables from "./../../pages/iclass/IclassCustomTables";
import ColorForm from "./../../pages/iclass/ColorForm";
import IclassReminder from "../../pages/iclass/IclassReminder";
import RetentionsCustomTables from "./../../pages/retentions/RetentionsCustomTables";
import RetentionCategories from "../../pages/retentions/RetentionCategories";
import HolidaysTable from "./../../pages/iclass/IclassHolidays";
import IclassSchedules from "./../../pages/iclass/IclassSchedules";
import IclassVT from "./../../pages/iclass/IclassVT";
import WebhooksTables from "../../pages/webhooks/config/WebhooksTables";
import IClassRestDays from "../../pages/iclass/IclassRestDays";
import AgreementsTable from "../../pages/retentions/AgreementsTable";

function DrawerConfig() {
	return (
		<Tabs tabPosition="left">
			{canGroup("Administrador Leads/Leadlets") && (
				<Tabs.TabPane tab="Ventas" key="sales">
					<Tabs>
						<Tabs.TabPane tab="Tablas de Leads" key="lead">
							<LeadCustomTables />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Tablas de Prospectos " key="leadlet">
							<LeadletCustomTables />
						</Tabs.TabPane>
					</Tabs>
				</Tabs.TabPane>
			)}
			{canGroup("Administrador SAC") && (
				<Tabs.TabPane tab="Tipificaciones" key="typifications">
					<Tabs>
						<Tabs.TabPane tab="Tablas" key="table">
							<TypificationsTable />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Categorías" key="categories">
							<Categories />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Mensajes Slack" key="slack">
							<TypificationsSlack />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Configuraciones" key="conf">
							<WizardConfigurationsTable />
						</Tabs.TabPane>
					</Tabs>
				</Tabs.TabPane>
			)}
			{canGroup("Administrador TI") && (
				<Tabs.TabPane tab="Escalamientos" key="escalations">
					<Tabs>
						<Tabs.TabPane tab="Tablas" key="table">
							<EscalationCustomTables />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Problemas" key="problems">
							<EscalationProblems />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Mensajes Slack" key="slack">
							<EscalationSlack
								updateChannelEndpoint="escalation_ti/escalation/update_channel/"
								currentChannelEndpoint="escalation_ti/escalation/get_channel/"
							/>
						</Tabs.TabPane>
						<Tabs.TabPane tab="Pruebas" key="tests">
							<EscalationTests />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Pruebas SAC" key="tests-sac">
							<SacTestTable />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Soluciones" key="solutions">
							<EscalationResults />
						</Tabs.TabPane>
					</Tabs>
				</Tabs.TabPane>
			)}
			{canGroup("Administrador Retiro") && (
				<Tabs.TabPane tab="Retiro de Equipos" key="remove_equipment">
					<Tabs>
						<Tabs.TabPane tab="Tablas " key="table">
							<RemoveEquipCustomTables />
						</Tabs.TabPane>
						<Tabs.TabPane
							tab="Plantilla Correos"
							key="email_template"
						>
							<RemoveEquipEmailTemplate />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Canal de Slack" key="slack_channel">
							<RemoveEquipmentChannel endpoint="formerCustomers/remove_equipament_channel/" />
						</Tabs.TabPane>
					</Tabs>
				</Tabs.TabPane>
			)}
			{canGroup("Administrador Comunicaciones") && (
				<Tabs.TabPane tab="Comunicaciones" key="communications">
					<Tabs>
						<Tabs.TabPane tab="Tablas de emails" key="table">
							<EmailCustomTables />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Tablas de WhatsApp" key="whatsapp">
							<WhatsappCustomTables />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Tablas de Texto" key="text">
							<TextCustomTables />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Tablas de Llamadas" key="calls">
							<CallsCustomTables />
						</Tabs.TabPane>
					</Tabs>
				</Tabs.TabPane>
			)}
			{canGroup("Administrador QC") && (
				<Tabs.TabPane tab="QC" key="qc">
					<Tabs>
						<Tabs.TabPane tab="Tablas" key="table">
							<InspeccionesCustomTables />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Indicadores" key="indicators">
							<IndicadoresTable />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Canales" key="channels">
							<CanalesTable />
						</Tabs.TabPane>
					</Tabs>
				</Tabs.TabPane>
			)}
			{canGroup("Agente Soporte") && (
				<Tabs.TabPane tab="Soporte" key="soporte">
					<Tabs>
						<Tabs.TabPane tab="Tablas" key="table">
							<TicketsCustomTables />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Slack" key="slack">
							<EscalationSlack
								updateChannelEndpoint="tech_support/ticket/update_channel/"
								currentChannelEndpoint="tech_support/ticket/get_channel/"
							/>
						</Tabs.TabPane>
					</Tabs>
				</Tabs.TabPane>
			)}
			{canGroup("Administrador Iclass") && (
				<Tabs.TabPane tab="Órdenes de servicio" key="so">
					<Tabs>
						<Tabs.TabPane tab="Tablas" key="table">
							<IclassCustomTables />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Colores" key="colors">
							<ColorForm />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Recordatorio" key="reminder">
							<IclassReminder />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Feriados" key="holiday">
							<HolidaysTable />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Horarios" key="schedules">
							<IclassSchedules />
						</Tabs.TabPane>
						<Tabs.TabPane
							tab="Visitas técnicas"
							key="technical_visits"
						>
							<IclassVT />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Canal de slack" key="slack">
							<RemoveEquipmentChannel endpoint="iclass/service_orders/so_channel/" />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Descanso" key="rest">
							<IClassRestDays />
						</Tabs.TabPane>
					</Tabs>
				</Tabs.TabPane>
			)}
			{canGroup("Administrador Retenciones") && (
				<Tabs.TabPane tab="Retenciones" key="retentions">
					<Tabs>
						<Tabs.TabPane tab="Tablas" key="table">
							<RetentionsCustomTables />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Canal de slack" key="slack">
							<RemoveEquipmentChannel endpoint="retentions/retention_channel/" />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Categorías" key="categories">
							<RetentionCategories />
						</Tabs.TabPane>
						<Tabs.TabPane tab="Acuerdos" key="agreements">
							<AgreementsTable />
						</Tabs.TabPane>
					</Tabs>
				</Tabs.TabPane>
			)}

			<Tabs.TabPane tab="Webhooks" key="wh">
				<Tabs>
					<Tabs.TabPane tab="Tablas" key="table">
						<WebhooksTables />
					</Tabs.TabPane>
				</Tabs>
			</Tabs.TabPane>
		</Tabs>
	);
}
export default DrawerConfig;
