import React from 'react';
import SearchTables from '../TypificationsTables/SearchTables';
import WhatsappTable from './WhatsappTable';

function WhatsappSearchTables() {
    const endpoint = 'communications/whatsapp_tables/'
    return (
        <>
            <SearchTables endpoint={endpoint} component={<WhatsappTable />} />
        </>
    )
}

export default WhatsappSearchTables;