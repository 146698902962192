import React, { useState } from "react";
import FullTable from "../../components/datatable/FullTable";
import { leadlet } from "../../config/permissions";
import { Button, Modal } from "antd";
import EmailDetails from "./EmailDetails";
import EmailForm from "./EmailForm";

function Emails(props) {
	const [tableRows, setTableRows] = useState([]);
	const [template, setTemplate] = useState("");
	const [details, setDetails] = useState(null);

	let endpoints = {
		columns:
			"communications/email/" + props.tableID + "/datatables_struct/",
		rows: "communications/email/" + props.tableID + "/datatables/",
		delete: `communications/email/`,
		download: "communications/email/" + props.tableID + "/download_data/",
	};
	const handleRows = (res) => {
		setTableRows(
			res.map((row) => ({
				...row,
				message: (
					<Button
						onClick={() => setDetails(row)}
						type={row.new_messages ? "primary" : "secondary"}
					>
						Ver mensaje
					</Button>
				),
			}))
		);
	};
	return (
		<>
			<FullTable
				modalContent={<EmailForm />}
				handleRows={handleRows}
				tableRows={tableRows}
				permissions={leadlet}
				endpoints={endpoints}
				tableName="correo"
			/>
			<Modal
				width={900}
				footer={null}
				visible={template}
				onCancel={() => setTemplate("")}
			>
				<iframe width="850" height="350" srcDoc={template}></iframe>
			</Modal>
			{details && (
				<Modal
					width={750}
					footer={null}
					visible={details}
					onCancel={() => setDetails(null)}
				>
					<EmailDetails details={details} setTemplate={setTemplate} />
				</Modal>
			)}
		</>
	);
}

export default Emails;
