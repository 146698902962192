import React, { useState, useEffect } from 'react';
import HttpRequest from '../../services/HttpRequest';
import { Tabs, Modal } from 'antd';
import TableTab from './TableTab';
import { connect } from 'react-redux';

function SearchTables(props) {
    const [tables, setTables] = useState([]);
    const endpoint = props.endpoint || 'tickets/typify_tables/'

    useEffect(() => {
        HttpRequest.endpoint = endpoint;
        HttpRequest.get({ operator: props.operator }).then(res => {
            const opTables = [];
            var propertys = {};
            res.forEach(table => {
                propertys = {};
                if (Number(table.operator) === Number(props.operator)) {
                    propertys.tableID = table.ID;
                    if (table.hasOwnProperty('hook_title')) {
                        propertys.hookTitle = table.hook_title;
                    }
                    opTables.push(
                        <Tabs.TabPane tab={table.name} key={table.ID}>
                            {props.endpoint ? React.cloneElement(props.component, propertys ) : <TableTab {...propertys} />}
                        </Tabs.TabPane>
                    )
                }
            })
            if (opTables.length === 0) {
                Modal.info({
                    title: 'Información',
                    content: 'No existen tablas definidas. Por favor intente con otro operador o cree una nueva.'
                })
            }
            setTables(opTables);
        })
    }, [props.operator])

    return (
        <>
            <Tabs className='tab-vertical'>
                {tables}
            </Tabs>
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(SearchTables);