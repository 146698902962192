import { Button, Spin, Tabs, Drawer, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import EscalationForm from './EscalationForm';
import Typify from './../typify/typify';
import HttpRequest from '../../services/HttpRequest';
import CustomerInfoModal from '../typify/customerInfoModal';
import ServiceInfoModal from '../typify/servicesInfoModal';
import EscalationHistory from './EscalationHistory';
import PhotoList from '../removeEquipment/PhotoList';

function EscalationFull(props) {
    const [escalation, setEscalation] = useState(null);
    const [loading, setLoading] = useState(true)
    const [customerModal, setCustomerModal] = useState(true);
    const [serviceModal, setServiceModal] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [showPhotos, setShowPhotos] = useState(false)

    const getEscalation = () => {
        HttpRequest.endpoint = 'escalation_ti/escalation';
        HttpRequest.get(null, props.id).then(res => {
            setEscalation(res)
            setLoading(false);
        })
    }
    const handleTabClick = e => {
        if (Number(e) === 1) {
            setCustomerModal(true)
            setServiceModal(false);
        } else {
            setServiceModal(true)
            setCustomerModal(false);
        }
    }

    useEffect(getEscalation, [])

    return (
        <>
            {!loading ?
                <>

                    <Tabs centered tabBarExtraContent={[<Button className='escalation-top-button' onClick={() => setShowPhotos(true)} type='primary' >Fotos</Button>, <Button className='escalation-top-button' onClick={() => setShowHistory(true)} type='primary'>Historial</Button>, <Button onClick={() => props.closeTab(props.tabID)}>Salir</Button>]}>
                        <Tabs.TabPane tab='Escalamiento' key='escalation'>
                            <EscalationForm id={props.id} />
                        </Tabs.TabPane>
                        {escalation && escalation.typify && <Tabs.TabPane tab='Tipificación' key='typify'>
                            <Typify id={escalation.typify} hideButtons hideTabs hideSmallButtons />
                        </Tabs.TabPane>}
                        {escalation && escalation.rut && <Tabs.TabPane tab='Cliente' key='Client'>
                            <Tabs centered onTabClick={handleTabClick}>
                                <Tabs.TabPane tab='Cliente' key='1'>
                                    <CustomerInfoModal notModal visible={customerModal} setVisible={setCustomerModal} rut={escalation.rut[0]} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab='Servicios' key='2'>
                                    <ServiceInfoModal notModal visible={serviceModal} setVisible={setServiceModal} rut={escalation.rut[0]} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Tabs.TabPane>}
                        {/*<Tabs.TabPane tab='Historial' key='history'>
                        historial
                    </Tabs.TabPane>*/}
                    </Tabs>
                    <Modal
                        title="Fotos"
                        width={800}
                        onCancel={() => setShowPhotos(false)}
                        visible={showPhotos}
                        footer={null}
                    >
                        <PhotoList endpoint="escalation_ti/escalation_photo/" photoFieldName='escalation' removeID={props.id} />
                    </Modal>
                    <Drawer title="Historial" placement="right" width='600px' closable={false} onClose={() => setShowHistory(false)} visible={showHistory} >
                        <EscalationHistory escalationID={props.id} />
                    </Drawer>
                </>
                :
                <Spin />
            }
        </>
    )
}

export default EscalationFull;